//
// Styled for Evrone ERP version of Bootstrap v4.4.0 (https://getbootstrap.com/)
// Copyright 2011-2019 The Bootstrap Authors
// Copyright 2011-2019 Twitter, Inc.
// Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
//

// imports.scss include "functions", "variables" and "mixins"
@import 'imports';
@import '~bootstrap';
@import './overrides';

.alert-dismissible {
  @include media-breakpoint-down(xs) {
    padding-left: $alert-padding-x * 0.5;
    padding-right: $close-font-size + $alert-padding-x * 0.5 * 2;
  }
}

// todo remove after bootstrap update https://tracker.yandex.ru/EVC-96
.gap-2 {
  gap: 4px;
}

.no-hover {
  pointer-events: none;
}
