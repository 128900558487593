// Index page
.business-hero {
  h2 {
    text-shadow: 0 0 1em black !important;
  }
  p {
    text-shadow: 0 0 7px black;
  }
}
.dropdown-item-sign_out {
  display: block;
  color: #555;
  padding: 4px 1.5rem;
  padding-right: 30px;
  &:hover {
    color: #555;
    background-color: #F8F9FA;
    text-decoration: none;
  }
}

.bg-light-red {
  background-color: #ffa1a1 !important;
}

.bg-light-green {
  background-color: #caffd4 !important;
}
.bg-light-green:hover {
  background-color: #caffd4 !important;
}
.hidden {
  display: none !important;
}
table.table-requirements {
  table-layout: fixed;
}
/*
* Probably needed fixing
*/
.total-time {
  position: absolute;
  right: 0;
  margin-right: 80px;
 }
table.table-requirements th:first-child { width: 5%; }
table.table-requirements th:first-child+th { width: 20%; }
table.table-requirements th:first-child+th+th { width: 20%; }
table.table-requirements th:first-child+th+th+th { width: 8%; }
table.table-requirements th:first-child+th+th+th+th { width: 5%; }
table.table-requirements th:first-child+th+th+th+th+th { width: 30%; }
table.table-requirements th:first-child+th+th+th+th+th+th { width: 10%; }
.bg-primary-skill {
  background-color: #d8d8d8 !important;
}
.bg-white {
  background-color: white;
}
.thead-default th {
  color: #495057;
  background-color: #e9ecef;
}
.form-inline .form-control {
  width: 100% !important;
}
.checkbox.checkbox-item {
  margin-left: 2em;
}
.checkbox.checkbox-item input {
  margin-right: 0.5em;
}

/* Additional info page */
.signup-page {
  background-color: white !important;
}
.intl-tel-input {
  display: inherit !important;
}
.intl-tel-input .selected-flag {
  padding: 0 17px 0 8px !important;
}
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
  padding-left: 80px !important;
}
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 90px !important;
}
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
  padding-left: 100px !important;
}

/* Bootstrap overrides styles */
.navbar-dark .dropdown-menu .dropdown-item[disabled=disabled] {
  color: #9e9e9e;
  cursor: default;

  &:hover {
    background: #fff;
  }
}

.footer-big-menu .menu .menu-link[disabled] {
  color: #9e9e9e;
  cursor: default;

  &:hover {
    color: #9e9e9e;
    text-decoration: none;
  }
}

.agency-contact-form form input[type="submit"]:disabled {
  background: darkgray !important;
  cursor: auto !important;
}

.business-hero.m-challenge-teaser {
  padding-top: 160px;
  padding-bottom: 120px;
  margin-bottom: -120px;

  @media only screen and (max-width: 991px) {
    min-height: auto;
  }
}

.btn {
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
}

.btn-shadow.btn-shadow-primary:active {
  background-image: linear-gradient(#2083fe, #1d7bf0) !important;
}

@media only screen and (max-width: 768px) {
  .fc .fc-right {
    width: 100%;
  }
}

/* Jquery ui-autocomplete-bootstrap */
.ui-autocomplete {
  position: absolute;
  z-index: 1000;
  cursor: default;
  padding: 0;
  margin-top: 2px;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  & > li {
    padding: 3px 20px;
  }

  & > li.ui-state-focus {
    background-color: #DDD;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

.ui-state-active,
.ui-state-focus {
  color: #23527c;
  background-color: #eeeeee;
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 250px;
}
