// nav bordered
.nav-bordered {
  &.nav-vertical {
    .nav-link.active:after {
      height: 80%;
      width: 3px;
      bottom: inherit;
      top: 2px;
      left: -5px;
      margin: auto 0;
    }
    
    .nav-header {
      text-transform: uppercase;
      padding: 0.5em 1em;
      color: #676767;
      letter-spacing: .5px;
      font-size: 90%;
    }

    li + .nav-header {
      margin-top: 20px;
    }
  }

  .nav-link {
    &.active {
      color: #333;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        background-color: #6f6f6f;
        width: 75%;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
