// COLORS (Bootstrap v4.0.0-beta.2)

$blue:         #007bff;
$indigo:       #6610f2;
$purple:       #6f42c1;
$pink:         #e83e8c;
$red:          #dc3545;
$orange:       #fd7e14;
$yellow:       #ffc107;
$green:        #28a745;
$green-acid:   #2bff00;
$teal:         #20c997;
$cyan:         #17a2b8;
$white:        #ffffff;
$gray:         #868e96;
$gray-dark:    #343a40;
$gray-light:   #dee2e6;
$primary:      #007bff;
$secondary:    #868e96;
$success:      #28a745;
$info:         #17a2b8;
$warning:      #ffc107;
$danger:       #dc3545;
$light:        #f8f9fa;
$dark:         #343a40;
$rating-blue:  #4278f5;

$transparent-white: rgba(255, 255, 255, 0.15);

$common-ui-transition-duration: 300ms !default;
$btn-transition-duration: 100ms;
