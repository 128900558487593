@import '../../shared/variables';

#main {
  transition: padding-right 0.5s;

  h5.mb-0 a {
    display: inline-block;
    width: 100%;
  }
}

// Timers
.timer {
  display: inline-block;
}
.time-counter {
  display: inline-block;
  width: 100px;
  font-family: monospace;
  font-size: 1.2em;
  text-align: center;
}

.play-pause-btn {
  position: absolute;
}

.play-btn {
  margin-left: 19px;
}

.pause-btn {
  margin-left: 17px;
}

.circle-timer {
  transform: rotate(-90deg);
}

.circle-timer-meter,
.circle-timer-value {
  fill: none;
}

.circle-timer-meter {
  stroke: $gray;
}

.circle-timer-value {
  stroke: $green-acid;
}

.practical-assignment-content {
  position: absolute;
  left: -9999px;
}

.open-practical-assignment-popup {
  margin-left: auto;
}

.verification-right-column {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100vh;
  background-color: $gray-light;
}

.event-logs-block {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: 32.5vh;
  padding-bottom: 10px;
}

.event-logs-entries {
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
}

.event-log-form-block {
  flex-shrink: 0;
}

.verifications-block {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.how-to-check-icon {
  color: $rating-blue;
}

.how-to-check-source {
  display: none;
}

.log-entry-time {
  margin-bottom: 0 !important;
  color: $gray;
  text-align: right;
}

.log-entry-content {
  margin-bottom: 0 !important;
}

.event-logs-placeholder {
  color: $gray;
  text-align: center;
}

.how-to-check-block {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: 25vh;
  padding-top: 10px;
}

.how-to-check-content {
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
}

.how-to-check-placeholder {
  position: relative;
  flex: 1 1 auto;
  color: $gray;
  text-align: center;
}

.how-to-check-placeholder-empty {
  display: none;
}

hr {
  width: 100%;
}
