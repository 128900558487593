@import '~bootstrap/scss/dropdown';

.dropdown-menu {
  @include media-breakpoint-up(lg) {
    display: block;
    @include transition-props(
                    (transform: scale(0), opacity: 0, visibility: hidden),
                    $btn-transition-duration, linear, $will-change: true);
    transform-origin: left top;
    z-index: $zindex-popover;

    &.show {
      @include transition-props(
                      (transform: scale(1), opacity: 1, visibility: visible),
                      $btn-transition-duration, linear, $only-functions: true);
    }

    &-right {
      transform-origin: right top;
    }
  }
}

.submenu {
  &__toggle {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: 0;
    text-align: inherit;
  }

  > .dropdown-menu {
    display: block;
  }

  @include media-breakpoint-down(lg) {
    > .dropdown-menu {
      box-shadow: none;
      border: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &__toggle {
      &::after {
        content: '▸';
        margin-left: 0.5rem;
      }
    }

    > .dropdown-menu {
      display: block;
      top: auto;
      left: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      @media(max-width: 1920px) {
        left: auto;
        right: 100%;
      }
    }

    &:hover > .dropdown-menu {
      @include transition-props(
                      (transform: scale(1), opacity: 1, visibility: visible),
                      $btn-transition-duration, linear, $only-functions: true);

      opacity: 1;
      transform: translateY(-2rem);
    }
  }
}
