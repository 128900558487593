.signup-page form {
  .form-field {
    margin-bottom: 0 !important;
    margin-left: 5px;
    text-align: left;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }
  .terms {
    margin-top: 0 !important;
  }
  .form-action {
    margin-top: 15px !important;
  }
}

.edit_user {
  #profile_image {
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
  }
  .text-info {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    vertical-align: top;
  }
}

.error-text-field {
  display: none;
  margin-top: -15px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-left: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
  text-align: center;
}

label .error-text-field {
  margin-top: 0;
  padding-bottom: 5px;
}

.user_phone .error-text-field {
  margin-top: 0;
  margin-bottom: -15px;
}

.signup-page form .form-action input[type="submit"] {
  &.disabled {
    cursor: default;
  }
}
