$images-path: "../../../../images" !default;

.business-hero {
  background: url('#{$images-path}/main-background.jpg');
  background-size: cover;
  background-position: center 0px;
  min-height: 100vh;
  padding-top: 220px;
  overflow: hidden;
  position: relative;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  @media(max-width: 991px) {
    padding-top: 100px;
    height: auto;
    padding-bottom: 100px;
  }

  .container {
    position: relative;
    z-index: 10;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, rgba(63, 89, 118, 0.3) 0, rgba(14, 20, 34, 0.5) 80%);
  }

  h2 {
    color: #fff;
    font-size: 48px;
    letter-spacing: .5px;
    font-family: $sourceSans;
    font-weight: 300;
    line-height: 54px;
    width: 800px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.11);

    @include animationDelay(.1s);

    @media(max-width: 991px) {
      font-size: 43px;
      line-height: 50px;
      width: auto;
    }

    @media(max-width: 767px) {
      text-align: center;
      font-size: 38px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 40px;
    width: 480px;
    font-size: 16px;
    line-height: 25px;

    @include animationDelay(.4s);

    @media(max-width: 991px) {
      width: auto;
    }

    @media(max-width: 767px) {
      text-align: center;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .actions {
    margin-top: 50px;

    @include animationDelay(.4s);

    @media(max-width: 767px) {
      text-align: center;
    }

    .btn-pill {
      font-weight: 600;
      padding: 13px 44px 16px;

      &:active {
        border-color: #3b6b23;
      }
    }
  }
}
