// Copied from `normas`
@mixin pseudo($selector, $display: block, $content: '') {
  #{$selector} {
    @if $display {
      display: $display;
    }
    content: $content;
    @content;
  }
}

@mixin both($display: block, $content: '') {
  @include pseudo('&::before, &::after', $display, $content) { @content; }
}

@mixin presence-props($props) {
  @each $prop, $value in $props {
    @if $value {
      #{$prop}: #{$value};
    }
  }
}

@mixin position($position, $top: false, $right: false, $bottom: false, $left: false) {
  @include presence-props((position: $position, top: $top, right: $right, bottom: $bottom, left: $left));
}

@mixin absolute($top: false, $right: false, $bottom: false, $left: false) {
  @include position(absolute, $top, $right, $bottom, $left);
}

@mixin disabled {
  cursor: default;
  pointer-events: none;
}

@mixin transition-props($props, $duration: $common-ui-transition-duration, $transition-function: ease, $will-change: false) {
  $transitions: ();
  $will-change-props: ();
  @each $prop, $value in $props {
    #{$prop}: $value;
    $tf: if($prop == visibility, if($value == hidden, step-end, step-start), $transition-function);
    $transitions: append($transitions, $prop $duration $tf, comma);
    @if $will-change {
      $will-change-props: append($will-change-props, $prop, comma);
    }
  }
  transition: $transitions;
  @if $will-change {
    will-change: $will-change-props;
  }
}

@mixin size($width, $height: $width) {
  @include presence-props((width: $width, height: $height));
}

@mixin ellipsis($display: inline-block, $width: 100%) {
  @if $display {
    display: $display;
  }
  @if $width {
    max-width: $width;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

// Copied from `shared-imports` from `core`
@mixin transition-props(
  $props,
  $duration: $common-ui-transition-duration,
  $transition-function: ease,
  $will-change: false,
  $only-functions: false
) {
  $transitions: ();
  $will-change-props: ();
  @each $prop, $value in $props {
    @if $value != false {
      #{$prop}: $value;
    }
    $tf: if($prop == visibility, if($value == hidden, step-end, step-start), $transition-function);
    $transitions: append($transitions, if($only-functions, $tf, $prop $duration $tf), comma);
    @if $will-change {
      $will-change-props: append($will-change-props, $prop, comma);
    }
  }
  @if $only-functions {
    transition-timing-function: $transitions;
  }
  @else {
    transition: $transitions;
  }
  @if $will-change {
    will-change: $will-change-props;
  }
}

@mixin transition-props-micro($props) {
  @include transition-props($props, $btn-transition-duration, linear);
}
