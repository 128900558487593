// footer big menu
.footer-big-menu {
  margin-top: 120px;
  padding: 80px 0 130px;
  background: #2B4255;
  position: relative;

  .cta {
    .cta-title {
      color: #fff;
      font-size: 1.6rem;
      margin-bottom: 40px;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

      @media(max-width: 767px) {
        font-size: 1.3rem;
      }
    }

    a {
      font-size: 12px;
    }

    .btn-outline {
      text-transform: uppercase;
      border: 2px solid #91bbe0;
      font-weight: 600;
      padding: 12px 23px;
      color: #b5dcff;
      border-radius: 0.25rem;
      letter-spacing: 0.5px;
      display: inline-block;

      @include transition(all .2s);

      &:hover {
        border-color: #fff;
        color: #fff;
      }

      @media (max-width: 575px) {
        margin-top: 20px;
      }
    }
  }

  .menu {
    margin-top: 100px;

    @media (max-width: 767px) {
      margin-top: 60px;
      text-align: center;
    }

    .col-md-3 {
      @media(max-width: 991px) {
        margin-bottom: 30px;
      }
    }

    .menu-title {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .menu-link {
      color: #97b0c5;
      display: block;
      margin-bottom: 2px;

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }
  }

  .bottom {
    color: #68849a;
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;

    a {
      color: #68849a;
      margin-left: 5px;
      display: inline-block;

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }
  }
}
