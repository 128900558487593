.index-intro-header {
  padding-top: 130px;

  @media(max-width: 991px) {
    padding-top: 100px;
  }

  @media(max-width: 767px) {
    padding-top: 80px;
  }

  h1 {
    font-size: 2.6rem;
    margin-bottom: 25px;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: #34404a;

    @media(max-width: 991px) {
      font-size: 29px;
    }

    @media(max-width: 767px) {
      font-size: 26px;
      line-height: 37px;
    }
  }

  p {
    font-size: 16px;
    color: #505c75;
    line-height: 28px;
    margin: 40px auto 0;

    @media(max-width: 991px) {
      font-size: 15px;
    }

    a {
      color: #222;
      font-weight: 500;
    }
  }
}
