$images-path: "../../../../images" !default;

.agency-contact-hero {
  min-height: 290px;
  position: relative;
  background-image: url('#{$images-path}/hero-02.jpg');
  background-size: cover;
  background-position: center -40px;

  @media(max-width: 767px) {
    background-position: center center;
    min-height: 270px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  h1 {
    position: relative;
    top: 95px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 40px;
    font-weight: 400;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

    @media(max-width: 767px) {
      font-size: 33px;
      top: 80px;
    }
  }

  h2 {
    position: relative;
    top: 100px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 28px;
    font-weight: 400;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

    @media(max-width: 767px) {
      font-size: 23px;
      top: 100px;
    }
  }

  p {
    position: relative;
    top: 115px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 17px;
    font-weight: 400;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

    @media(max-width: 767px) {
      font-size: 14px;
      top: 100px;
    }
  }

  h3 {
    position: relative;
    top: 200px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 29px;
    font-weight: 400;
    line-height: 43px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

    @media(max-width: 767px) {
      font-size: 25px;
      top: 130px;
    }
  }
}
