@import '../shared/variables';

.progress {
  position: relative;
  height: 30px !important;

  .full-score {
    z-index: 1;
  }
}

.pass-score-mark {
  position: absolute;
  width: 7px;
  height: 100%;
  background-color: $primary;

  background-image: linear-gradient(45deg, $transparent-white 25%, transparent 25%, transparent 50%, $transparent-white 50%, $transparent-white 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
