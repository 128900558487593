.move-buttons {
  float: right;
}

.move-col .move-requirement {
  display: none;
}

.form-control-success {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(75, 231, 92, 0.35) !important;
  border-color: #64c752 !important;
}

.form-control-fail {
  box-shadow: inset 0 1px 1px rgba(255, 37, 37, 0.07), 0 0 0 3px rgba(231, 75, 75, 0.35) !important;
  border-color: #c75252 !important;
}
