@import '../../shared/variables';

.big-col {
  width: 60%;
}
.small-col {
  width: 20%;
}
.verify_description {
  margin: 35px;
  padding: 10px;
  border-radius: 5px;
  background-color: $green;
  background-image: linear-gradient(45deg, $transparent-white 25%, transparent 25%, transparent 50%, $transparent-white 50%, $transparent-white 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  color: $white;
}
