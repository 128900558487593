body {
  @include font-smoothing;
}

.navbar-dark {
  background-color: #282F37 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

  @include transition(all .4s);

  @media(min-width: 992px) {
    padding: 0 1rem;
  }

  .navbar-brand {
    font-size: 21px;
    letter-spacing: .5px;
    color: #fff;

    @include transition(color .2s linear);
  }
  
  .dropdown-menu {
    @media(min-width: 992px) {
      border-color: #fbfbfb;
      box-shadow: 0 13px 20px rgba(0, 0, 0, 0.07);
      margin-top: -1px;
      border-radius: 0 0 0.25rem 0.25rem;

      .dropdown-item {
        color: #555;
        padding: 4px 1.5rem;
        padding-right: 30px;

        &:active {
          background-color: #f7f7f9;
        }
      }
    }
  }

  .nav-item {
    .nav-link {
      color: #dadada;
      font-weight: 500;
      font-size: 13px;

      @include transition(color .15s linear);

      @media(min-width: 992px) {
        margin: 0 9px;
        padding: 32px 10px;
      }

      &:hover,
      &:focus {
        color: #fff;
        background: none;
      }

      &--rounded {
        border: 0;

        @media(min-width: 1200px) {
          text-transform: capitalize;
          border: 1px solid #868DA0;
          color: #E3E6EA;
          border-radius: 25px;
          margin-left: 20px;
          margin-top: 22px;
          padding: 8px 20px 9px;
  
          @include transition(all .15s ease-out);
  
          &:hover {
            border-color: #fff;
          }
  
          &:active {
            @include transform(scale(0.93));
          }
        }
      }

      i {
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }
}

/* footer */
.footer {
  margin-top: 100px;
  background: #283142;
  padding: 55px 0 35px;

  .col-md-4 {
    @media(max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .menu {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;

    a {
      color: #CDD6E9;
      display: inline-block;
      margin-bottom: 9px;
      text-decoration: none;

      @include transition(all .2s linear);

      &:hover {
        color: #fff;
      }
    }

    i {
      font-size: 23px;
      margin-right: 12px;
      width: 21px;
      position: relative;
      top: 3px;
      margin-bottom: 5px;
    }
  }

  .bottom {
    margin-top: 40px;
    border-top: 1px solid #3A455A;

    ul {
      list-style-type: none;
      padding: 0;
      text-align: center;
      margin-top: 15px;

      li {
        display: inline-block;
        margin: 0 11px;

        &:last-child {
          a:after {
            content: '';
          }
        }
      }

      a {
        color: #9DAAC2;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        text-decoration: none;
        position: relative;

        &:after {
          content: '|';
          position: absolute;
          right: -15px;
          top: -1px;
          font-weight: bold;
        }
      }
    }
  }
}
