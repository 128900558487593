.error-page {
  &__head {
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    letter-spacing: 0.01rem !important;
  }

  &__text {
    margin: 30px auto 50px !important;
    line-height: 25px !important;
  }
}
