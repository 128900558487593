$bezier: cubic-bezier(0.23, 1, 0.32, 1);
$slideTime: 400ms;

// calendar width calculation
$daySize: $input-height-sm;
$dayMargin: 2px;
$daysWidth: $input-height * 7 + 2px;
$calendarWidth: $daysWidth;

$monthNavHeight: 28px !default;
$weekdaysHeight: 28px !default;
$timeHeight: 40px;
$noCalendarBorder: false;

// Colors

$calendarBackground: #fff !default;
$calendarBorderColor: $input-focus-border-color;

$monthForeground: rgba(black, 0.9) !default;
$arrow_hover_color: $link-hover-color;

$monthBackground: transparent !default;

$weekdaysBackground: transparent !default;
$weekdaysForeground: rgba(black, 0.54) !default;

$dayForeground: #393939 !default;
$dayHoverBackground: #e6e6e6 !default;

$todayColor: #959ea9 !default;
$today_fg_color: false;
$selectedDayBackground: #569ff7 !default;
$selectedDayForeground: false;
$disabled_border_color: false;

$invertedBg: invert($calendarBackground);
