@import '../../shared/variables';

.b-scopes {
  .btn {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.b-scope-content {
  display: none;
  margin-top: 20px;

  &.active { display: block; }
}

.b-challenge-modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0;

  @media only screen and (max-width: 424px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  .card {
    width: 28%;
    min-height: 275px;
    margin: 0 2% 30px;

    &.should_not_pass {
      color: $gray;
    }

    &.passed {
      transition: background-color 300ms;
      border-color: $teal;
      background-color: rgba($teal, .3);
      &:hover { background-color: rgba($teal, .5); }
    }

    &.should_pass {
      transition: background-color 300ms;
      border-color: $success;
      background-color: rgba($success, .3);
      &:hover { background-color: rgba($success, .5); }
    }

    &.can_pass {
      transition: background-color 300ms;
      border-color: $info;
      background-color: rgba($info, .3);
      &:hover { background-color: rgba($info, .5); }
    }

    &.can_not_pass {
      transition: background-color 300ms;
      border-color: $danger;
      background-color: rgba($danger, .3);
      &:hover { background-color: rgba($danger, .5); }
    }

    .btn-disabled {
      cursor: default;
    }

    .card-text {
      margin-bottom: 5px;

      + .btn { margin-top: 20px; }
    }

    @media only screen and (max-width: 991px) {
      .btn {
        display: block;
        white-space: normal;
      }
    }

    @media only screen and (max-width: 767px) {
      width: 46%;
    }

    @media only screen and (max-width: 424px) {
      width: 100%;
      margin: 0 0 30px;
    }
  }
}

.grade-title {
  font-size: 1.77em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 10px;
  font-weight: 500;
}
