// Disabled RFS from Bootstrap
// See: https://getbootstrap.com/docs/4.4/content/typography/#responsive-font-sizes

@mixin rfs($fs, $important: false) {
  $rfs-suffix: if($important, ' !important', '');
  font-size: #{$fs}#{$rfs-suffix};
}

// The font-size & responsive-font-size mixin uses RFS to rescale font sizes
@mixin font-size($fs, $important: false) {
  @include rfs($fs, $important);
}

@mixin responsive-font-size($fs, $important: false) {
  @include rfs($fs, $important);
}
