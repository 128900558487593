@import '../shared/variables';

body,
html {
  margin: 0;
  padding: 0;
}

.b-certificate {
  position: relative;
  overflow: hidden;
  font-family: FLight;
  text-align: center;

  &[size="A4"][layout="landscape"] {
    width: 29.7cm;
    height: 21.191cm;
  }

  &[size="A4"][layout="portrait"] {
    width: 21.191cm;
    height: 29.7cm;
  }

  .b-body {
    position: absolute;
    top: 1.008cm;
    left: 0.96cm;
    width: 93%;
    height: 90%;
    border: 3px solid $gray-dark;
    border-right: 4px solid $gray-dark;
    border-left: 4px solid $gray-dark;
  }

  &__title {
    padding-top: 7%;
    text-align: center;
  }
  &__body {
    display: block;
    width: 100%;
    height: 42%;
    margin-top: 70px;
    padding: 4% 0 0;
  }
  &__footer {
    display: block;
    width: 100%;
  }
  &__wrapper {
    padding: 0 1cm;
  }
  &__org-logo {
    display: block;
    position: absolute;
    top: -2.3cm;
    width: 4.7cm;
    height: 4.7cm;
  }
  &__org {
    position: absolute;
    left: 11.45cm;
  }
  &__head {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
    color: $red;
    font-family: FBlack;
    font-size: 3em;
    font-weight: bold;
  }
  &__head-specialization {
    margin-top: 0;
    font-family: FBold;
    font-size: 2em;
  }
  &__user-name {
    font-family: FBold;
    font-size: 3em;
  }
  &__cert-text {
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1.8em;

    span {
      font-weight: bold;
    }
  }
  &__cert-data {
    .cert-data-title {
      font-size: 0.7em;
    }
  }
  &__cert-specialist {
    margin-left: 60px;
    float: left;
    font-size: 1.8em;
  }
  &__stamp {
    position: absolute;
    top: 13.5cm;
    left: 11.5cm;
  }
  &__stamp-image {
    width: 5cm;
    // have to use prefixes for rotation
    // scss-lint:disable VendorPrefix
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  &__cert-desc {
    margin-right: 60px;
    float: right;
    font-size: 1.8em;
   }
}
