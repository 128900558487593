.g-form {
  .form-error {
    display: none;
    color: #dc3545;
  }
  input.error {
    border-color: #dc3545 !important;
    border-style: solid;

    &:focus {
      background: #fff;
      outline-offset: -2px;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
  .m-input-error ~ .error-text-field {
    display: block;
  }
}

.form-checkbox {
  .form-group.m-input-error ~ .form-checkbox__text {
    color: #dc3545;
  }
}

.g-center-header {
  text-align: center;
  line-height: 1.8em;
  margin: 40px auto;
  max-width: 900px;
}

.b-challenge-accepted {
  text-align: center;
  padding-top: 40px;

  h1 {
    margin-bottom: 1rem;
  }

  &__text {
    margin-bottom: 3rem;
  }
  &__desc {
    text-align: left;
    display: inline-block;
    max-width: 500px;
    padding-top: 30px;
  }
}

.g-page { // used when page begins without any Heading, but content.
  padding-top: 3rem;
  padding-bottom: 3rem;
}

a.disabled {
  color: gray;
  pointer-events: none;
}

.graduated {
  font-size: 2em;
  color: $green;
}
