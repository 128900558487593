@import '../../shared/variables';

.admin-head-panel {
  margin-bottom: 50px;

  &__btns {
    margin-top: 24px;
    float: right;
    @media only screen and (max-width: 576px) {
      margin-top: 10px;
      float: none;
    }
  }
}

.admin-content {
  margin-bottom: 50px;
}

.admin-panel-btns {
  padding-top: 20px;
}

#aasm_buttons {
  display: inline-block;
  width: 100%;

  h6 {
    display: inline-block;
  }
  .button_to {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 500px) {
  #aasm_buttons {
    h6 {
      display: block;
    }
  }
}

.aasm_buttons__btns {
  padding: 10px 0 0;
}


// CHALLENGE MODULES CARDS


.b-challenge-modules-table {
  .text-label {
    color: $gray;
    font-weight: 300;
  }

  & &__heading {
    @media only screen and (max-width: 991px) { display: none; }
  }

  &__body {
    .text-label {
      display: none;
      @media only screen and (max-width: 991px) { display: inline; }
    }
  }
}

.b-tag {
  display: inline-block;
  max-width: 100%;
  margin-right: 0.25rem;
  margin-bottom: 0.01rem;
  padding: 0.1rem 0.25rem;
  overflow: hidden;
  border-radius: 5px;
  background-color: $light;
  color: $gray-dark;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: 991px) { display: inline; }
}
