// B4 dropdown

// TODO: Move this styles to shared/bootstrap/_dropdown.scss
.dropdown-menu {
  font-size: 0.9rem;

  @include media-breakpoint-up(lg) {
    opacity: 0;
    visibility: hidden;

    @include transform(translateY(8px));
    @include transition(all .3s);
  }

  &.dropdown-menu-dark {
    background: #282f37 !important;
    border-color: #242a31 !important;

    .dropdown-item {
      color: #dadada !important;

      &:hover,
      &:active,
      &:focus {
        color: #fff !important;
        background: #31353e !important;
      }
    }

    .dropdown-divider {
      border-top: 1px solid #191e23;
    }
  }
}

.show>.dropdown-menu {
  @include media-breakpoint-up(lg) {
    opacity: 1;
    visibility: visible;
    z-index: 1000;

    @include transform(none);
  }
}

.navbar .dropdown-toggle::after {
  display: none;
}

// .dropdown-extend new class for bigger menus

.dropdown-extend {
  position: initial;
}

.dropdown-extend-menu {
  width: 100%;

  @media(min-width: 768px) {
    padding: 20px 30px;
  }

  .dropdown-item {
    @media(min-width: 768px) {
      margin: 5px 0;
    }

    i {
      margin-right: 3px;
      font-size: 14px;
      color: #6b7386;

      @media(max-width: 767px) {
        display: none;
      }
    }
  }
}
